import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

const GallerySection = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-bottom: 10px;
  div {
    min-height: 600px;
  }

  @media only screen and (min-width: 48em) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 75em) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const Gallery = () => (
  <GallerySection>
    <StaticImage
      src="../images/gallery/3.jpg"
      quality={95}
      placeholder="blurred"
      objectFit="cover"
      layout="fullWidth"
      alt="Ein Bild im Atelier"
      title="Ein Bild im Atelier"
    />
    <StaticImage
      src="../images/gallery/1.jpg"
      quality={95}
      placeholder="blurred"
      objectFit="cover"
      layout="fullWidth"
      alt="Ein Bild im Atelier"
      title="Ein Bild im Atelier"
    />
    <StaticImage
      src="../images/gallery/2.jpg"
      quality={95}
      placeholder="blurred"
      objectFit="cover"
      layout="fullWidth"
      alt="Ein Bild im Atelier"
      title="Ein Bild im Atelier"
    />
    <StaticImage
      src="../images/gallery/13.jpg"
      quality={95}
      placeholder="blurred"
      objectFit="cover"
      layout="fullWidth"
      alt="Ein Bild im Atelier"
      title="Ein Bild im Atelier"
    />
    <StaticImage
      src="../images/gallery/4.jpg"
      quality={95}
      placeholder="blurred"
      objectFit="cover"
      layout="fullWidth"
      alt="Ein Bild im Atelier"
      title="Ein Bild im Atelier"
    />
    <StaticImage
      src="../images/gallery/5.jpg"
      quality={95}
      placeholder="blurred"
      objectFit="cover"
      layout="fullWidth"
      alt="Ein Bild im Atelier"
      title="Ein Bild im Atelier"
    />
    <StaticImage
      src="../images/gallery/6.jpg"
      quality={95}
      placeholder="blurred"
      objectFit="cover"
      layout="fullWidth"
      alt="Ein Bild im Atelier"
      title="Ein Bild im Atelier"
    />
    <StaticImage
      src="../images/gallery/7.jpg"
      quality={95}
      placeholder="blurred"
      objectFit="cover"
      layout="fullWidth"
      alt="Ein Bild im Atelier"
      title="Ein Bild im Atelier"
    />
    <StaticImage
      src="../images/gallery/8.jpg"
      quality={95}
      placeholder="blurred"
      objectFit="cover"
      layout="fullWidth"
      alt="Ein Bild im Atelier"
      title="Ein Bild im Atelier"
    />
    <StaticImage
      src="../images/gallery/9.jpg"
      quality={95}
      placeholder="blurred"
      objectFit="cover"
      layout="fullWidth"
      alt="Ein Bild im Atelier"
      title="Ein Bild im Atelier"
    />
    <StaticImage
      src="../images/gallery/10.jpg"
      quality={95}
      placeholder="blurred"
      objectFit="cover"
      layout="fullWidth"
      alt="Ein Bild im Atelier"
      title="Ein Bild im Atelier"
    />
    <StaticImage
      src="../images/gallery/11.jpg"
      quality={95}
      placeholder="blurred"
      objectFit="cover"
      layout="fullWidth"
      alt="Ein Bild im Atelier"
      title="Ein Bild im Atelier"
    />
  </GallerySection>
)

export default Gallery
