import React from "react"
import styled from "styled-components"

import AtelierHeroImage from "../../images/atelier.jpg"
import Logo from "../../images/logo_symbol_white.svg"

const HeroSection = styled.section`
  padding: 10rem 0;
  background: linear-gradient(
      90deg,
      rgba(33, 34, 39, 0.5) 0%,
      rgba(33, 34, 39, 0.5) 100%
    ),
    url(${AtelierHeroImage});
  background-size: cover;
  background-position: center center;

  /* Width in PX > 1200px */
  /* ==== = LARGE = ==== */
  @media only screen and (min-width: 75em) {
    background-attachment: fixed;
  }
`

const AtelierHero = () => {
  return (
    <HeroSection
      className="m b-16 flex items-center h-screen"
      style={{ minHeight: "700px" }}
    >
      <div className="container mx-auto px-6 flex">
        <div className="flex flex-col items-center h-full w-full mt-12">
          <h1 className="text-white text-3xl lg:text-5xl leading-tight text-center mb-3 leading-relaxed">
            Willkommen in meinem Atelier
          </h1>
          <img src={Logo} alt="Tailorsnook Logo" style={{ height: "45px" }} />
        </div>
      </div>
    </HeroSection>
  )
}

export default AtelierHero
