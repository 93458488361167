import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import AtelierHero from "../components/ui/atelierHero"
import Gallery from "../components/gallery"

const AtelierPage = () => (
  <Layout>
    <Seo title="Atelier" />
    <AtelierHero />
    <Gallery />
  </Layout>
)

export default AtelierPage
